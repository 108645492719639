exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alta-automatica-gracias-js": () => import("./../../../src/pages/alta-automatica/gracias.js" /* webpackChunkName: "component---src-pages-alta-automatica-gracias-js" */),
  "component---src-pages-alta-automatica-index-js": () => import("./../../../src/pages/alta-automatica/index.js" /* webpackChunkName: "component---src-pages-alta-automatica-index-js" */),
  "component---src-pages-alta-con-asesor-gracias-js": () => import("./../../../src/pages/alta-con-asesor/gracias.js" /* webpackChunkName: "component---src-pages-alta-con-asesor-gracias-js" */),
  "component---src-pages-alta-con-asesor-index-js": () => import("./../../../src/pages/alta-con-asesor/index.js" /* webpackChunkName: "component---src-pages-alta-con-asesor-index-js" */),
  "component---src-pages-gracias-js": () => import("./../../../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sistema-de-pedidos-js": () => import("./../../../src/pages/sistema-de-pedidos.js" /* webpackChunkName: "component---src-pages-sistema-de-pedidos-js" */)
}

